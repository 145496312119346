/* ================================================================================================================== */
/*  Animations                                                                                                        */
/* ================================================================================================================== */


.fadeIn {
    animation: fadeInAnimation ease 0.64s;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.spinner  {
  #ekWT1s3JqB73_to {
      animation: ekWT1s3JqB73_to__to 2200ms linear infinite normal forwards;
  }
  
  @keyframes ekWT1s3JqB73_to__to {
      0% {
          transform: translate(50.526278px, 121.472996px);
      }
      63.636364% {
          transform: translate(50.526278px, 121.472996px);
      }
      81.818182% {
          transform: translate(68.53px, 103.47px);
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      100% {
          transform: translate(50.526278px, 121.472996px);
      }
  }
  
  #ekWT1s3JqB78_to {
      animation: ekWT1s3JqB78_to__to 2200ms linear infinite normal forwards;
  }
  
  @keyframes ekWT1s3JqB78_to__to {
      0% {
          transform: translate(85.991348px, 136.175346px);
      }
      54.545455% {
          transform: translate(85.991348px, 136.175346px);
      }
      72.727273% {
          transform: translate(85.991348px, 111.18px);
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      90.909091% {
          transform: translate(85.991348px, 136.175346px);
      }
      100% {
          transform: translate(85.991348px, 136.175346px);
      }
  }
  
  #ekWT1s3JqB713_to {
      animation: ekWT1s3JqB713_to__to 2200ms linear infinite normal forwards;
  }
  
  @keyframes ekWT1s3JqB713_to__to {
      0% {
          transform: translate(121.491001px, 121.472828px);
      }
      45.454545% {
          transform: translate(121.491001px, 121.472828px);
      }
      63.636364% {
          transform: translate(103.49px, 103.47px);
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      81.818182% {
          transform: translate(121.491001px, 121.472828px);
      }
      100% {
          transform: translate(121.491001px, 121.472828px);
      }
  }
  
  #ekWT1s3JqB718_to {
      animation: ekWT1s3JqB718_to__to 2200ms linear infinite normal forwards;
  }
  
  @keyframes ekWT1s3JqB718_to__to {
      0% {
          transform: translate(136.174355px, 85.999596px);
      }
      36.363636% {
          transform: translate(136.174355px, 85.999596px);
      }
      54.545455% {
          transform: translate(111.17px, 85.999596px);
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      72.727273% {
          transform: translate(136.174355px, 85.999596px);
      }
      100% {
          transform: translate(136.174355px, 85.999596px);
      }
  }
  
  #ekWT1s3JqB723_to {
      animation: ekWT1s3JqB723_to__to 2200ms linear infinite normal forwards;
  }
  
  @keyframes ekWT1s3JqB723_to__to {
      0% {
          transform: translate(121.471821px, 50.526798px);
      }
      27.272727% {
          transform: translate(121.471821px, 50.526798px);
      }
      45.454545% {
          transform: translate(103.47px, 68.53px);
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      63.636364% {
          transform: translate(121.471821px, 50.526798px);
      }
      100% {
          transform: translate(121.471821px, 50.526798px);
      }
  }
  
  #ekWT1s3JqB728_to {
      animation: ekWT1s3JqB728_to__to 2200ms linear infinite normal forwards;
  }
  
  @keyframes ekWT1s3JqB728_to__to {
      0% {
          transform: translate(85.989544px, 35.842449px);
      }
      18.181818% {
          transform: translate(85.989544px, 35.842449px);
      }
      36.363636% {
          transform: translate(85.989544px, 60.84px);
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      54.545455% {
          transform: translate(85.989544px, 35.842449px);
      }
      100% {
          transform: translate(85.989544px, 35.842449px);
      }
  }
  
  #ekWT1s3JqB733_to {
      animation: ekWT1s3JqB733_to__to 2200ms linear infinite normal forwards;
  }
  
  @keyframes ekWT1s3JqB733_to__to {
      0% {
          transform: translate(50.543236px, 50.5268px);
      }
      9.090909% {
          transform: translate(50.543236px, 50.5268px);
      }
      27.272727% {
          transform: translate(68.54px, 68.53px);
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      45.454545% {
          transform: translate(50.543236px, 50.5268px);
      }
      100% {
          transform: translate(50.543236px, 50.5268px);
      }
  }
  
  #ekWT1s3JqB738_to {
      animation: ekWT1s3JqB738_to__to 2200ms linear infinite normal forwards;
  }
  
  @keyframes ekWT1s3JqB738_to__to {
      0% {
          transform: translate(35.840449px, 86.009148px);
      }
      18.181818% {
          transform: translate(60.840449px, 86.009148px);
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      36.363636% {
          transform: translate(35.840449px, 86.009148px);
      }
      100% {
          transform: translate(35.840449px, 86.009148px);
      }
  }
  
}
