/* ================================================================================================================== */
/*  Font sizes                                                                                                        */
/* ================================================================================================================== */

.text-base {
  font-size: 16px !important;
}
.text-lg {
  font-size: 20px !important;
}
.text-xl {
  font-size: 24px !important;
}
.text-2xl {
  font-size: 32px !important;
}
.text-3xl {
  font-size: 72px !important;
}

/* ================================================================================================================== */
/*  Font weight                                                                                                       */
/* ================================================================================================================== */

.font-light {
  font-weight: 500 !important;
}
.font-normal {
  font-weight: 600 !important;
}
.font-medium {
  font-weight: 700 !important;
}
.font-bold {
  font-weight: 800 !important;
}

/* ================================================================================================================== */
/*  Line height                                                                                                       */
/* ================================================================================================================== */

.leading-1 {
  line-height: 24px !important;
}
.leading-2 {
  line-height: 32px !important;
}
.leading-3 {
  line-height: 40px !important;
}
.leading-max {
  line-height: 88px !important;
}

/* ================================================================================================================== */
/*  Letter spacing                                                                                                    */
/* ================================================================================================================== */

.tracking-1 {
  letter-spacing: -8px !important;
}
.tracking-2 {
  letter-spacing: -1px !important;
}
.tracking-3 {
  letter-spacing: -0.5px !important;
}
