/* ================================================================================================================== */
/*  Main                                                                                                              */
/* ================================================================================================================== */


body {
  background-size: cover !important;

  @media (max-width: 767px) {
    background: white;
  }
}

/* ================================================================================================================== */
/*  Material Components                                                                                               */
/* ================================================================================================================== */

@media (max-width: 767px) {
  .mat-mdc-card {
    box-shadow: none !important;
  }
}
