@use "variable" as *;

/* ================================================================================================================== */
/*  Background                                                                                                        */
/* ================================================================================================================== */

.bg-primary {
  background-color: $red !important;
}
.bg-grey {
  background-color: $grey !important;
}
.bg-white {
  background-color: white !important;
}
