@use '@angular/material' as mat;

@use "assets/scss/font" as *;
@use "assets/scss/main.scss" as *;
@use "assets/scss/animations.scss" as *;
@use "assets/scss/background.scss" as *;
@use "assets/scss/colors.scss" as *;
@use "assets/scss/text.scss" as *;
@use "assets/scss/variable.scss" as *;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
$my-primary: mat.m2-define-palette(mat.$m2-red-palette, A700);
$my-accent: mat.m2-define-palette(mat.$m2-red-palette, A700);
$my-typography: mat.m2-define-typography-config($font-family: 'Manrope');

$my-theme: mat.m2-define-light-theme((
    color: (
        primary: $my-primary,
        accent: $my-accent,
    ),
    typography: $my-typography,
    density: 0,
));

@include mat.typography-hierarchy($my-typography);
@include mat.all-component-themes($my-theme);

html, body { height: 100%; }
body {
    color: #3C3C3D;
    margin: 0;
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    line-height: 24px;
}

// Scroll bar
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #757575;
}
