@use "variable" as *;

/* ================================================================================================================== */
/*  Colors                                                                                                        */
/* ================================================================================================================== */

.color-primary {
  color: $red !important;
}
.color-secondary {
  color: $dark !important;
}
