/*--------------------------------------------------------------------------------------------------------------------*/
/*  Manrope                                                                                                       */
/*--------------------------------------------------------------------------------------------------------------------*/
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  src: url('https://cdn.isifid.com/common/fonts/Manrope-ExtraBold.ttf') format('truetype')
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: url('https://cdn.isifid.com/common/fonts/Manrope-Bold.ttf') format('truetype')
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: semi-condensed;
  src: url('https://cdn.isifid.com/common/fonts/Manrope-SemiBold.ttf') format('truetype')
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: url('https://cdn.isifid.com/common/fonts/Manrope-Medium.ttf') format('truetype')
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn.isifid.com/common/fonts/Manrope-Regular.ttf') format('truetype')
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  src: url('https://cdn.isifid.com/common/fonts/Manrope-Light.ttf') format('truetype')
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  src: url('https://cdn.isifid.com/common/fonts/Manrope-ExtraLight.ttf') format('truetype')
}
